@import 'vars.scss';


html,
body {
  height: 100%;
}

body {
  margin: 0;
  position: relative;
  font-family: $font-family-primary;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px $light-gray;
  border: solid 4px transparent;
  border-radius: 14px;
  transition: box-shadow .3s ease;

  &:hover {
      box-shadow: inset 0 0 14px 14px darken($light-gray, 10%);
  }
}

::-webkit-scrollbar-button {
  display: none;
}
@keyframes fillToRight {
  0% {
    width: 0;
  }
  80% {
    width: calc(100% + 20px);
  }
  100% {
    width: calc(100% + 20px);
  }
}

@keyframes fillToRightLoading {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes rotateRight {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@keyframes appearDownToUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes disappearUpToDown {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes appearOpacity {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

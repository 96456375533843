@import "vars.scss";

.cdk-overlay-container {

  .cdk-overlay-pane {

    &.email-template {
      max-width: 100% !important;

      .mat-mdc-dialog-container {
        min-width: 900px;

        .mat-mdc-dialog-content {
          padding: 0;
          margin-bottom: 20px;

          .title {
            margin-top: 0;
          }
        }

        .input-wrap {
          margin-bottom: 0;

          &.textarea-wrap {

            .mat-mdc-form-field {
              padding-bottom: 0;

              .mat-mdc-text-field-wrapper {
                height: auto;

                .mat-mdc-form-field-infix {
                  height: auto;

                  textarea {
                    height: 400px;
                    resize: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.json-edit-modal {
      max-width: 900px !important;

      .mat-mdc-dialog-container {
        min-width: 900px;

        .dialog-message {

          p {
            margin: 0;
          }
        }

        .actions {
          text-align: right;
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    min-width: 400px;
    color: $text-color-standard;

    .mat-mdc-dialog-inner-container {

      .mat-mdc-dialog-surface {
        padding: 47px 40px 32px;
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 0 6px #00000029;
        // this is needed to have/show the drop shadow effect of the button
        overflow: visible;

        .mat-mdc-dialog-component-host {
          font-family: $font-family-primary;
          color: $text-color-standard;

          .mat-mdc-dialog-title {
            margin: 0 0 20px;
            padding: 0;
            color: $text-color-standard;

            &::before {
              display: none;
            }
          }

          .header {

            .title {
              margin: 0;
              font-size: 24px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              word-break: break-word;
              color: $text-color-standard;
            }
          }
      
          .content {
            padding: 20px 0 0;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: $grey-text;
            text-align: left;
            word-break: break-word;
      
            p {
              margin: 0;
            }
          }

          .mat-mdc-dialog-actions {
            padding: 8px 0;
          }
        }
      }
    }

    .actions {
      padding-top: 32px;
      text-align: right;

      .button {

        &:nth-child(2) {
          margin-left: 24px;
        }
      }
    }
  }
}
html.loading-API {

  .page-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    z-index: 10;

    .loader-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::before {
        display: inline-block;
        content: '';
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 4px solid $secondary;
        border-top-color: $blue;
        animation: spin 1s infinite linear;
        transform: rotate(45deg);
      }
    }
  }

  .container-content {
    position: relative;
    filter: blur(8px);

    &.stopLoader {
      filter: none;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(404deg);
  }
}

@import 'src/scss/vars.scss';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  padding: 30px 0 70px;
  background-color: $white;
  background-image: url('src/assets/images/login-background.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &.initial-loading {

    .login-loader {
      animation-name: disappearUpToDown;
      animation-duration: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .login-widget {
      animation-name: appearDownToUp;
      animation-duration: .3s;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
    
    .login-page-footer {
      animation-name: appearOpacity;
      animation-duration: .3s;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .login-loader {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 40px;
    left: 0;
    transform-origin: 50% 50%;

    .loader-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .loader-image {
      text-align: center;
      margin-bottom: 40px;
      z-index: 2;

      img {
        width: auto;
        height: 40px;
        transform: translateX(-4px);
      }
    }

    .loader-progress {
      width: 180px;
      height: 6px;
      margin: 0 auto;
      position: relative;
      background-color: rgba($black, .1);
      border-radius: 3px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 3px;
        background-color: $black;
        animation-name: fillToRightLoading;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .login-widget {
    width: 360px;
    margin: 0 auto;
    padding: 20px 36px 36px;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 10px 10px 30px #aeaec066;
    opacity: 0;
    visibility: hidden;

    &.loading {
      cursor: progress;

      .login-form-wrap {

        input {
          cursor: progress;
        }

        .form-buttons-wrap {

          .button-login {
            cursor: progress;
          }
        }
      }

      .login-widget-branding {

        .loader {
          transform: translateX(-50%) scale(1);
        }
      }
    }

    .login-widget-branding {
      padding: 35px 25px 55px;
      border-bottom: 1px solid #ddd;
      position: relative;

      img {
        display: block;
        margin: 0 auto;
        max-width: 205px;
        max-height: 110px;
      }

      .loader {
        display: inline-block;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%) scale(0);
        background-color: $white;
        box-shadow: 0 0 0 10px #fff;
        transform-origin: 50% 50%;
        transition: transform .3s ease;

        .loader-inner {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 2px solid rgb(243, 243, 243);
          border-radius: 50%;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            border-top: 2px solid $primary;
            border-right: 2px solid rgb(243, 243, 243);
            border-left: 2px solid rgb(243, 243, 243);
            border-radius: 50%;
            transform: rotate(45deg);
            animation-name: rotateRight;
            animation-iteration-count: infinite;
            animation-duration: 1.2s;
            animation-timing-function: linear;
          }
        }
      }
    }

    .login-form-wrap {
      padding: 30px 0 0;

      .title {
        margin: 0 0 20px;
        font-size: 24px;
        line-height: 29px;
      }

      .subtitle {
        margin: 0 0 30px;
        font-size: 14px;
        line-height: 20px;
        color: $grey-text;
      }

      .validation-rules-wrap {

        .password-requirements {
            font-size: 14px;
            line-height: 20px;
            color: $grey-text;
        }
      }

      .extra-buttons-wrap {
        margin: 0 auto;
        text-align: center;

        .text {
          display: inline-block;
          padding-right: 5px;
          font-size: 14px;
          line-height: 17px;
          color: $grey-text;
        }

        .button-forgotten-pass {
          display: inline-block;
          font-size: 14px;
          line-height: 17px;
          color: $primary;
          text-decoration: none;
        }
      }

      .form-buttons-wrap {
        margin: 14px 0 36px;

        .button-login {
          width: 100%;
          padding: 15px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          border-radius: 8px;
          text-align: center;
          text-shadow: 0 2px 4px rgba(6,16,54,0.2);

          &:enabled {
            position: relative;
            box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
                0 10px 40px 0 rgba(74, 116, 255, 0.3);
            border: none;
            color: $white;
            z-index: 1;
          
            &::before,
            &::after {
              display: inline-block;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 8px;
            }
        
            &::before {
              background-image: linear-gradient($primary 0.11%, #4e6ffe 100%);
              z-index: -2;
            }
        
            &::after {
              background-color: $primary-hover;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
              z-index: -1;
            }
          
            &:hover {
        
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
        
            &:active {
        
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
          
            &:focus {
              outline: none;
        
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:disabled {
            background-image: none;
            background-color: $disabled;
            border: none;
            color: $unselected-light;
            cursor: not-allowed;
            box-shadow: none;
            text-shadow: none;
        
            &:hover {
              background-color: $disabled;
            }
          }
        }
      }
    }
  }

  .login-page-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 15px 60px 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    .logo {

      img {
        max-height: 28px;
      }
    }

    .links {
      display: flex;
      align-items: center;

      a {
        padding: 0 5px;
        margin-left: 25px;
        font-weight: 700;
      }
    }

    a {
      display: inline-block;
      font-family: $font-family-primary;
      font-size: 14px;
      line-height: 16px;
      color: $text-color-standard;
      text-decoration: none;
      transition: color .3s ease;
    }
  }

  .mat-mdc-form-field.mat-form-field-custom {
    padding-bottom: 10px;

    .mat-mdc-text-field-wrapper {
      height: 42px;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {
          height: 40px;
          min-height: 40px;
          padding-top: 16px;
          padding-bottom: 0;
  
          .mat-mdc-floating-label {
            top: 20px;
            font-size: 14px;
          }
  
          .button-unmask {
            top: 9px;
            padding: 0 3px;
  
            .unmask {
  
              &::before {
                left: 8px;
                top: 10px;
                width: 1px;
                height: 18px;
                transform-origin: 50% 50% 0;
              }
            }
          }
        }
      }
    }
  }
}
